<template>
  <div class="page">
    <van-row>
      <van-col span="3" @click="back()"
        ><div class="back-btn"><van-icon name="arrow-left" />返回</div></van-col
      >
      <van-col span="21">
        <van-search
          v-model="keyword"
          placeholder="请输入搜索关键词"
          ref="keyword"
        ></van-search>
      </van-col>
    </van-row>
    <van-row class="title">
      <van-col span="24">咨询列表</van-col>
    </van-row>
    <van-list
      :finished="finishedShow"
      finished-text="没有更多了"
      class="consults"
      :immediate-check="false"
      @load="loadTaskList"
    >
      <template #default>
        <van-row
          class="consult bdc-b1s-gray"
          v-for="consult in consultList"
          :key="consult.code"
        >
          <van-col span="24">
            <van-row>
              <van-col span="24" class="name text-short">{{
                consult.taskName
              }}</van-col>
            </van-row>
            <van-row>
              <van-col span="12" class="type"
                >类型：<van-tag v-if="consult.type === 'DOC'" :color="COLOR_M"
                  >资料</van-tag
                ><van-tag v-if="consult.type === 'ORD'" :color="COLOR_S1"
                  >普通</van-tag
                ><van-tag v-if="consult.type === 'VIP'" :color="COLOR_S2"
                  >高级</van-tag
                ></van-col
              >
              <van-col span="12" class="state"
                >状态：<van-tag v-if="consult.state === 'WAT'" :color="COLOR_S1"
                  >待处理</van-tag
                ><van-tag v-if="consult.state === 'CPT'" :color="COLOR_M"
                  >已完成</van-tag
                ></van-col
              >
            </van-row>
            <van-row>
              <van-col span="12" class="result"
                >结果：<span v-if="consult.state === 'WAT'">未知</span
                ><van-tag v-if="consult.result === 'SUC'" :color="COLOR_M"
                  >成功</van-tag
                ><van-tag v-if="consult.result === 'FAL'" :color="COLOR_S2"
                  >失败</van-tag
                ></van-col
              >
              <van-col span="12" class="time"
                >时间：{{ consult.createTime }}</van-col
              >
            </van-row>
            <van-row class="operator" v-if="model === 'TSF'">
              <van-col span="6">
                <van-button
                  icon="eye-o"
                  class="btn"
                  :color="COLOR_M"
                  size="mini"
                  @click="taskView(consult.taskCode)"
                  >任务</van-button
                >
              </van-col>
              <van-col span="6">
                <van-button
                  icon="description"
                  class="btn"
                  :color="COLOR_M"
                  size="mini"
                  @click="personView(consult.personCode)"
                  >资料</van-button
                >
              </van-col>
              <van-col span="6">
                <van-button
                  icon="phone-circle-o"
                  class="btn"
                  :color="COLOR_S1"
                  size="mini"
                  @click="callPhone(consult.userCode)"
                  >回访</van-button
                >
              </van-col>
              <van-col span="6">
                <van-button
                  icon="success"
                  class="btn"
                  :color="COLOR_S2"
                  size="mini"
                  @click="consultResult(consult.code)"
                  >反馈</van-button
                >
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <van-row class="no-record" v-if="consultList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import { Search, Image, Popup, Icon, List, Field, Tag } from 'vant'
export default {
  components: {
    Share: Share,
    Loading: Loading,
    [Image.name]: Image,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Field.name]: Field,
    [Tag.name]: Tag
  },
  data () {
    return {
      shareShow: false,
      loadingShow: false,
      model: '',
      page: { current: 0, size: 50, last: 0 },
      consultList: []
    }
  },
  mounted () {
    this.model = window.sessionStorage.getItem(this.SESSION_SELLER_MODEL)
    document.title = '客户咨询'
    this.retrieveConsultList()
  },
  methods: {
    loadTaskList () {
      if (this.page.last >= this.page.current) {
        this.retrieveConsultList()
      } else {
        this.finishedShow = true
      }
    },
    async retrieveConsultList () {
      this.loadingShow = true
      var userCode = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { refereeCode: userCode, current: this.page.current, size: this.page.size }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/retrieveRefereeConsultList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.consultList = res.data
        this.page.last = res.page.last
      }
      this.loadingShow = false
    },
    consultResult (code) {
      this.$router.push({ path: '/seller/consultResult', query: { code: code } })
    },
    taskView (code) {
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/recruit/detail', query: { taskCode: code } })
    },
    personView (code) {
      if (code !== '' && code !== undefined && code !== null) {
        var url = window.location.href
        window.sessionStorage.setItem(window.const.global.PEOPLE_PERSON_VIEW_BACK, url)
        this.$router.push({ path: '/people/person/view', query: { personCode: code } })
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '未提交资料无法查看'
        })
      }
    },
    async callPhone (code) {
      var pd = { code: code }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveUser', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.location.href = 'tel:' + res.data.phone
      }
    },
    initShare () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var title = '个人电子资料'
      var desc = '完善您的个人电子资料，便于工作、证书、上学等咨询服务'
      var link = 'https://moc.utopanet.com/member/person?profile=' + this.profileKey + '&referee=' + user
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    },
    back () {
      this.$router.push({ path: '/seller/own' })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 120px;
  .back-btn {
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
  }
  .title {
    height: 25px;
    line-height: 25px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    color: #fff;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
  }
  .consults {
    width: 96%;
    margin: 0px auto;
    .consult {
      padding: 10px 10px;
      .name {
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        margin: 2px auto;
      }
      .type {
        font-size: 13px;
        text-align: left;
        margin: 2px auto;
      }
      .state {
        text-align: left;
        margin: 2px auto;
      }
      .result {
        text-align: left;
        margin: 2px auto;
      }
      .time {
        text-align: left;
        margin: 2px auto;
      }
      .operator {
        margin: 2px auto;
        padding-top: 10px;
        border-top: 1px dashed #eee;
        .btn {
          width: 80%;
        }
      }
    }
  }
  .shareImg {
    width: 90%;
  }
}
</style>
